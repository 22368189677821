<template>
  <div class="full-line flex-col align-start">
    <div class="text-14 dark-font-color">下单时间</div>
    <div class="time_container full-line flex-row align-center space-between">
      <div
        :class="[
          'time_button',
          'full-height',
          'flex-col',
          'align-center',
          'justify-center',
          'text-12',
          startTime == '' ? 'grey-bg-color' : 'base-bg-color',
          startTime == '' ? 'light-dark-font-color' : 'white-font-color',
        ]"
        @click="showDatePicker(0)"
      >
        {{ startTime == '' ? "开始时间" : startTime }}
      </div>
      <div class="time_separate"></div>
      <div
        :class="[
          'time_button',
          'full-height',
          'flex-col',
          'align-center',
          'justify-center',
          'text-12',
          endTime == '' ? 'grey-bg-color' : 'base-bg-color',
          endTime == '' ? 'light-dark-font-color' : 'white-font-color',
        ]"
        @click="showDatePicker(1)"
      >
        {{ endTime === "" ? "结束时间" : endTime }}
      </div>
    </div>
    <div class="text-14 dark-font-color title">订单状态</div>
    <div
      class="section_container flex-row flex-wrap"
      :style="'height:' + statusHeight + 'px;'"
    >
      <div
        v-for="(status, index) in orderStatus"
        :key="index"
        :class="[
          'section_item',
          'flex-col',
          'align-center',
          'justify-center',
          'text-12',
          orderStatusKey === index + 1 ? 'base-bg-color' : 'grey-bg-color',
          orderStatusKey === index + 1
            ? 'white-font-color'
            : 'light-dark-font-color',
        ]"
        @click="statusClick(status)"
      >
        {{ status.value }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    filterOption: {
      type: Object,
      default: () => {
        return {
          startTime: "",
          endTime: "",
          orderStatusKey: "",
          orderTypeKey: "",
        };
      },
    },
  },
  data: function () {
    return {
      startTime: this.filterOption.startTime,
      endTime: this.filterOption.endTime,
      orderStatusKey: this.filterOption.orderStatusKey,
      orderTypeKey: this.filterOption.orderTypeKey,
      orderStatus: [
        {
          key: 1,
          value: "草稿",
        },
        {
          key: 2,
          value: "询价中",
        },
        {
          key: 3,
          value: "部分询价",
        },
        {
          key: 4,
          value: "已询价",
        },
        {
          key: 5,
          value: "已生成订单",
        },
      ],
      buttonIndex: 0,
    };
  },
  computed: {
    statusHeight: function () {
      const line = Math.floor((this.orderStatus.length + 2) / 3);
      return line * 29 + (line - 1) * 10;
    },
    typeHeight: function () {
      const line = Math.floor((this.orderType.length + 2) / 3);
      return line * 29 + (line - 1) * 10;
    },
    sourceHeight: function () {
      const line = Math.floor((this.orderSource.length + 2) / 3);
      return line * 29 + (line - 1) * 10;
    },
  },
  methods: {
    statusClick: function (status) {
      this.orderStatusKey = status.key;
    },
    typeClick: function (type) {
      this.orderTypeKey = type.key;
    },
    sourceClick: function (source) {
      this.orderSourceKey = source.key;
    },
    getOption: function () {
      return {
        startTime: this.startTime,
        endTime: this.endTime,
        orderStatusKey: this.orderStatusKey,
        orderTypeKey: this.orderTypeKey,
        orderSourceKey: this.orderSourceKey,
      };
    },
    showDatePicker(index) {
      this.buttonIndex = index;
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: "请选择时间",
          value: new Date(),
          onSelect: this.selectHandle,
          onCancel: this.cancelHandle,
        });
      }
      this.datePicker.show();
    },
    selectHandle: function (date) {
      if (this.buttonIndex == 0) {
        this.startTime = dayjs(date).format("YYYY-MM-DD");
      } else {
        this.endTime = dayjs(date).format("YYYY-MM-DD");
      }
      console.log(dayjs(date).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style scoped>
.time_container {
  margin-top: 10px;
  flex: 0 0 29px;
  height: 29px;
}

.time_button {
  width: 90px;
  border-radius: 3px;
}

.time_separate {
  width: 15px;
  height: 1px;
  background-color: #e1e1e1;
}

.title {
  margin-top: 20px;
}

.section_container {
  justify-content: space-between;
  align-content: space-between;
  margin-top: 10px;
}

.section_item {
  width: calc(33% - 5px);
  flex: 0 0 calc(33% - 5px);
  height: 29px;
  border-radius: 3px;
}
</style>